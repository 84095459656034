import {Component, ElementRef, NgZone, OnDestroy, OnInit} from '@angular/core';
import {
  BezoekschipOrganisation,
  Country,
  CustomsOffice,
  GetOrganisationByShortName,
  GetVisit,
  IE3AdditionalSupplyChainActor,
  IE3CL214,
  IE3CL750,
  IE3ConsignmentMasterLevel,
  IE3ConsignmentProcess,
  IE3EnsStatus,
  IE3FilingType,
  IE3FindCustomsOfficesFirstEuCountry,
  IE3GetConsignment,
  IE3HouseConsignment,
  IE3InvalidateConsignment,
  IE3Party,
  IE3Place,
  IE3SaveConsignment,
  IE3SupportingDocument,
  IE3TransportEquipment,
  IE3Vehicle,
  Port,
  TaskMessageStatus,
  Terminal,
  Vessel,
  Visit,
  VisitSummary
} from "@portbase/bezoekschip-service-typescriptmodels";
import {
  checkValidity,
  clearValidation,
  cloneObject,
  formDataSaved,
  isDefined,
  openConfirmationModalWithCallback,
  publishEvent,
  removeItem,
  sendCommand,
  sendQuery
} from "../../../../common/utils";
import {ConsignmentRouteInfo} from "./master-consignment-route/master-consignment-route.component";
import {AppContext} from "../../../../app-context";
import {
  ConsignmentUtils,
  EquipmentWithPlacement,
  GoodsItemSummaryWithHouseConsignments,
} from "../../consignment.utils";
import {PortvisitUtils} from "../../../../refdata/portvisit-utils";
import {Observable, of} from "rxjs";
import {WebsocketService} from "../../../../common/websocket.service";
import lodash, {cloneDeep} from "lodash";
import {EventGateway} from "../../../../common/event-gateway";
import {map, mergeMap, tap} from "rxjs/operators";
import {ModalConfirmAutofocus, ModalConfirmAutofocusData} from "../../../../common/modal/modal-confirm.component";
import typesOfMeansOfTransport from "../tables/consignment-vehicles-table/types-of-means-of-transport.json";
import {HouseConsignmentDetailsComponent} from "../house-consignment-details/house-consignment-details.component";
import {VisitContext} from "../../../../visit-details/visit-context";

@Component({
  selector: 'app-master-consignment-details',
  templateUrl: './master-consignment-details.component.html',
  styleUrls: ['./master-consignment-details.component.scss'],
  providers: [WebsocketService]
})
export class MasterConsignmentDetailsComponent implements OnInit, OnDestroy {
  utils = ConsignmentUtils;
  refData = PortvisitUtils;
  consignmentProcess: IE3ConsignmentProcess;
  data: MasterConsignmentDetailsComponentData;
  countries: Map<string, Country>;
  isNewConsignment: boolean;
  editMode: boolean;
  customsOffices: CustomsOffice[] = [];
  _routes: ConsignmentRouteInfo[] = [];
  mrn: string;
  billOfLadingFilingType: BillOfLadingFilingType = {};
  billOfLadingFilingTypeMapper: { [index: string]: BillOfLadingFilingType } = {
    [IE3FilingType.F10]: {
      modeOfTransportType: ModeOfTransportType.Maritime,
      billOfLadingType: BillOfLadingType.Straight,
      filing: Filing.Single
    },
    [IE3FilingType.F11]: {
      modeOfTransportType: ModeOfTransportType.Maritime,
      billOfLadingType: BillOfLadingType.Master,
      filing: Filing.Single
    },
    [IE3FilingType.F12]: {
      modeOfTransportType: ModeOfTransportType.Maritime,
      billOfLadingType: BillOfLadingType.Master,
      filing: Filing.Multiple
    },
    [IE3FilingType.F13]: {
      modeOfTransportType: ModeOfTransportType.Maritime,
      billOfLadingType: BillOfLadingType.Straight,
      filing: Filing.Multiple
    },
    [IE3FilingType.F50]: {modeOfTransportType: ModeOfTransportType.Road, billOfLadingType: null, filing: Filing.Single},
    [IE3FilingType.TSD]: {
      modeOfTransportType: ModeOfTransportType.Maritime,
      billOfLadingType: null,
      filing: Filing.Single
    },
    [IE3FilingType.TSR]: {
      modeOfTransportType: ModeOfTransportType.Maritime,
      billOfLadingType: BillOfLadingType.Master,
      filing: Filing.Multiple
    },
  };
  typeOfIdentifications: IE3CL750[] = [IE3CL750.N10, IE3CL750.N20, IE3CL750.N21, IE3CL750.N30, IE3CL750.N31, IE3CL750.N41, IE3CL750.N80];
  billOfLadingTypes: BillOfLadingType[] = Object.values(BillOfLadingType);
  modeOfTranportTypes: ModeOfTransportType[] = Object.values(ModeOfTransportType);
  filingTypes: Filing[] = Object.values(Filing);

  houseConsignments: IE3HouseConsignment[];
  equipments: EquipmentWithPlacement[];
  goodsItems: GoodsItemSummaryWithHouseConsignments[] = [];
  terminalsOfVisit: Observable<Terminal[]>;
  itineraryOfVisit: Observable<PortItinerary[]>;
  portItinerary: PortItinerary[];
  isPassingThrough: boolean;
  private initialised: boolean;
  private readonly registration: () => void;

  constructor(private websocket: WebsocketService<IE3ConsignmentProcess>, private ngZone: NgZone, private eventGateway: EventGateway, private elementRef: ElementRef) {
    this.registration = this.eventGateway.registerLocalHandler(this);
  }

  bulkAuthorisationFormatter = (value: BezoekschipOrganisation[]) => value.map(PortvisitUtils.bulkAuthorisationFormatter).join(", ");
  searchVessel = term => sendQuery('com.portbase.bezoekschip.common.api.visit.FindVessels', {term: term})
    .pipe(map((results: Vessel[]) => {
      return lodash.flatMap(results, vessel => <IE3Vehicle>{
        name: vessel.name,
        identificationNumber: vessel.imoCode,
        typeOfMeansOfTransport: vessel.motUnCode,
        nationality: vessel.flagCountryUnCode,
        typeOfIdentification: IE3CL750.N10
      });
    }));
  vehicleFormatter = (vehicle: IE3Vehicle) => vehicle?.name ? vehicle.name + ' – ' + vehicle.identificationNumber : '';

  getCustomsOfficeProviders = (): Observable<CustomsOffice[]> => {
    return (this.consignmentProcess?.consignmentMasterLevel.activeBorderTransportMeans?.itinerary ?
      sendQuery('com.portbase.bezoekschip.common.api.consignments.queries.FindCustomsOfficesFirstEuCountry', <IE3FindCustomsOfficesFirstEuCountry>{
        countryUnCodes: this.countriesForCustomsOfficeOfFirstEntry
      }) : of([]));
  };

  refreshCustomsOfficeProvider = () => this.getCustomsOfficeProviders()
    .subscribe(c => this.customsOffices = [null].concat(c));

  ngOnInit(): void {
    this.isNewConsignment = !this.data.consignmentProcessId && !this.data.cachedConsignmentProcess;
    if (this.isNewConsignment) {
      this.editMode = true;
      if (this.data.copyOfConsignmentProcessId) {
        sendQuery("com.portbase.bezoekschip.common.api.consignments.queries.GetConsignment", <IE3GetConsignment>{
          consignmentProcessId: this.data.copyOfConsignmentProcessId
        }, {
          caching: false
        }).subscribe((c: IE3ConsignmentProcess) => {
          this.consignmentProcess = c;
          this.consignmentProcess.status = null;
          this.consignmentProcess.ensStatus = IE3EnsStatus.UNKNOWN;
          this.consignmentProcess.ensTaskStatus = TaskMessageStatus.UNKNOWN;
          this.consignmentProcess.consignmentMasterLevel.consignmentNumber = null;
          this.consignmentProcess.consignmentMasterLevel.consignmentsHouseLevel?.forEach(value => value.consignmentNumber = null);
          this.updateFromConsignment(this.consignmentProcess, true);
        });
      } else {
      this.consignmentProcess = this.createNewConsignment(this.data.transportEquipmentMap, this.data.crn);
      this.updateFromConsignment(this.consignmentProcess);
      }
    } else {
      this.websocket.initialise(`/api/ui/${btoa(this.data.consignmentProcessId)}`,
        (update: IE3ConsignmentProcess) => this.updateFromConsignment(update), true);
      if (!this.data.cachedConsignmentProcess) {
        sendQuery("com.portbase.bezoekschip.common.api.consignments.queries.GetConsignment", <IE3GetConsignment>{
          consignmentProcessId: this.data.consignmentProcessId
        }, {
          caching: false
        }).subscribe(c => this.updateFromConsignment(c, true));
      } else {
        this.updateFromConsignment(this.data.cachedConsignmentProcess, true);
      }
    }
    ConsignmentUtils.getCountries().subscribe(c => {
      this.countries = c;
      this.updateRoutes();
    });
  }

  ngOnDestroy() {
    this.registration();
  }

  get masterLevel(): IE3ConsignmentMasterLevel {
    return this.consignmentProcess.consignmentMasterLevel;
  }

  get itinerary(): string[] {
    return this.masterLevel.activeBorderTransportMeans.itinerary;
  }

  'cachedConsignmentUpdated' = (c: IE3ConsignmentProcess) => {
    if (c?.consignmentProcessId === this.consignmentProcess.consignmentProcessId) {
      this.updateFromConsignment(c);
    }
  }

  onPlaceOfLoadingChange = (placeOfLoading: IE3Place) => {
    this.masterLevel.placeOfLoading = placeOfLoading;
    this.tryUpdateDepartureTimes(placeOfLoading);
    this.updateRoutes();
    this.updateFilingType();
  }

  onPlaceOfUnloadingChange = (placeOfUnloading: IE3Place) => {
    this.masterLevel.placeOfUnloading = placeOfUnloading;
    this.masterLevel.dischargeTerminal = null;
    this.masterLevel.terminalLicense = null;
    this.updateRoutes();
    this.updateFilingType();
  }

  onTerminalChange = (terminal: Terminal) => {
    this.masterLevel.terminalLicense = null;
    if (terminal?.organisationShortName != null) {
      sendQuery("com.portbase.bezoekschip.common.api.authorisation.GetOrganisationByShortName", <GetOrganisationByShortName>{
        shortName: terminal.organisationShortName
      }, {
        caching: true
      }).subscribe(t => this.masterLevel.terminalLicense = t.rtoNumber);
    }
    this.updateRoutes();
  }

  private tryUpdateDepartureTimes = (placeOfLoading: IE3Place) => {
    if (placeOfLoading) {
      this.itineraryOfVisit?.subscribe(i => {
        const portItinerary = i.reduce((a, b) =>
          b.port.locationUnCode === placeOfLoading.locationUnCode ? b : a, i[0]);
        if (portItinerary) {
          this.masterLevel.activeBorderTransportMeans.estimatedDateAndTimeOfDeparture = portItinerary.departure;
        }
      });
    }
  }

  private updateFromConsignment = (c: IE3ConsignmentProcess, updateEditMode?: boolean) => {
    this.consignmentProcess = c;
    if (updateEditMode && !ConsignmentUtils.hasBeenDeclared(c) && this.isEditable) {
      this.editMode = true;
    }
    if (c.filing.crn) {
      this.updateDataFromVisit(c.filing.crn, false);
    }
    this.consignmentProcess.status = this.consignmentProcess.status || {};
    this.masterLevel.previousDocument = this.masterLevel.previousDocument || {};
    this.masterLevel.transportCharges = this.masterLevel.transportCharges || {};
    this.masterLevel.exchangeRate = this.masterLevel.exchangeRate || {};
    this.masterLevel.bulkAuthorisations = this.masterLevel.bulkAuthorisations || [];
    this.mrn = this.consignmentProcess.status.filingStatusENS?.ensRegistrationResponse?.mrn;
    this.billOfLadingFilingType = cloneObject(this.billOfLadingFilingTypeMapper[c.filing.filingType]) || {};
    if (c.filing.filingType === IE3FilingType.TSD) {
      this.billOfLadingFilingType.billOfLadingType = isDefined(c.filing.straight) ? c.filing.straight
        ? BillOfLadingType.Straight : BillOfLadingType.Master : null;
    }
    this.refreshCustomsOfficeProvider();
    this.updateRoutes();
    this.masterLevel.transportCharges = this.masterLevel.transportCharges || {};
    this.equipments = Object.entries(this.masterLevel.transportEquipmentMap)
      .map(([containerIdentificationNumber, equipment]) => (<EquipmentWithPlacement>{
        equipment: equipment,
        placement: null,
        goodsItems: ConsignmentUtils.getGoodsOfEquipment(this.consignmentProcess, containerIdentificationNumber),
        houseConsignments: this.masterLevel.consignmentsHouseLevel
          .filter(h => ConsignmentUtils.getEquipmentNumbersOfHouseConsignment(
            this.consignmentProcess, h).includes(containerIdentificationNumber))
          .map(h => h.consignmentNumber)
      }));
    this.houseConsignments = this.masterLevel.consignmentsHouseLevel
      .map(h => {
        const equipmentList = ConsignmentUtils.getEquipmentNumbersOfHouseConsignment(
          this.consignmentProcess, h).map(
          e => this.masterLevel.transportEquipmentMap[e]);
        return {
          consignmentNumber: h.consignmentNumber,
          consignee: h.consignee,
          consignor: h.consignor,
          goodsItems: h.goodsItems.map(g => ({
            goodsItemNumber: g.goodsItemNumber,
            placements: g.goodsPlacements,
            grossWeight: g.grossWeight,
            commodity: g.commodity
          })),
          equipments: equipmentList.map(t => ({
            equipmentNumber: t.containerIdentificationNumber,
            empty: t.empty,
            sizeType: t.containerSizeAndType
          }))
        }
      });
    this.goodsItems = ConsignmentUtils.toConsignmentGoodsItemsSummaries(this.masterLevel.goodsItems)
      .map(g => (<GoodsItemSummaryWithHouseConsignments>{
        goodsItem: g,
        houseConsignments: this.masterLevel.consignmentsHouseLevel
          .filter(h => h.goodsItems.some(
            hg => hg.commodity?.commodityCode?.harmonizedSystemSubHeadingCode === g.commodity?.commodityCode?.harmonizedSystemSubHeadingCode))
          .map(h => h.consignmentNumber),
        clearance: ConsignmentUtils.getClearanceOfGoodsItem(this.consignmentProcess.status, g.goodsItemNumber)
      }));
    this.initialised = true;
    this.updateFilingType();
  }

  getRoutesOfConsignment = (): Observable<ConsignmentRouteInfo[]> => {
    if (!this.consignmentProcess || !this.countries) {
      return of([]);
    }
    if (this.itineraryOfVisit) {
      return this.itineraryOfVisit
        .pipe(tap(s => this.masterLevel.activeBorderTransportMeans.itinerary = this.getItineraryBasedOnVisit(s)))
        .pipe(mergeMap(v => this.getCustomsOfficeProviders()))
        .pipe(map(c => {
          this.customsOffices = c;
          return this.createRoutesInfo();
        }));
    }
    this.masterLevel.activeBorderTransportMeans.itinerary = this.getItineraryOutsideDutchPort();
    return of(this.createRoutesInfo());
  }

  private getItineraryOutsideDutchPort = (): string[] => {
    const itinerary = cloneDeep(this.masterLevel.activeBorderTransportMeans.itinerary);
    const placeOfAcceptance = this.masterLevel.placeOfAcceptance;
    const placeOfLoading = this.masterLevel.placeOfLoading;
    const placeOfUnloading = this.masterLevel.placeOfUnloading;
    const placeOfDelivery = this.masterLevel.placeOfDelivery;
    if (placeOfAcceptance && !itinerary.includes(placeOfAcceptance.countryUnCode)) {
      itinerary.unshift(placeOfAcceptance.countryUnCode);
    }
    const indexOfAcceptance = placeOfAcceptance ? itinerary.indexOf(placeOfAcceptance.countryUnCode) : -1;
    if (placeOfLoading && !itinerary.includes(placeOfLoading.countryUnCode)) {
      itinerary.splice(indexOfAcceptance + 1, 0, placeOfLoading.countryUnCode);
    }
    if (placeOfDelivery && !itinerary.includes(placeOfDelivery.countryUnCode)) {
      itinerary.push(placeOfDelivery.countryUnCode);
    }
    const indexPlaceOfDelivery = placeOfDelivery ? itinerary.indexOf(placeOfDelivery.countryUnCode) : -1;
    if (placeOfUnloading && !itinerary.includes(placeOfUnloading.countryUnCode)) {
      indexPlaceOfDelivery > -1 ? itinerary.splice(indexPlaceOfDelivery, 0, placeOfUnloading.countryUnCode)
        : itinerary.push(placeOfUnloading.countryUnCode);
    }
    return itinerary;
  }

  private getItineraryBasedOnVisit = (s: PortItinerary[]): string[] => {
    const placeOfLoading = this.consignmentProcess.consignmentMasterLevel.placeOfLoading;
    const indexOfStartLocation = lodash.findLastIndex(s, (p, i) => placeOfLoading
      ? p.port.countryUnCode === placeOfLoading.countryUnCode : i === 0);
    const itinerary = (placeOfLoading ? [ConsignmentUtils.getCountryCode(placeOfLoading)] : [])
      .concat(s.slice(indexOfStartLocation === -1 ? 0 : indexOfStartLocation).map(p => ConsignmentUtils.getCountryCode(p.port)))
      .concat(this.masterLevel.placeOfUnloading ? [ConsignmentUtils.getCountryCode(this.masterLevel.placeOfUnloading)] : []);
    return this.getUniqueConsecutive(itinerary);
  }

  private createRoutesInfo = (): ConsignmentRouteInfo[] => {
    return ConsignmentUtils.createRoutesInfo(this.consignmentProcess, this.countries, this.masterLevel.activeBorderTransportMeans.itinerary,
      this.masterLevel.placeOfAcceptance, this.masterLevel.placeOfDelivery);
  }

  get equipmentSupported(): boolean {
    return !this.consignmentProcess.filing.filingType || [IE3FilingType.F11, IE3FilingType.F12, IE3FilingType.TSD,
      IE3FilingType.TSR].includes(this.consignmentProcess.filing.filingType);
  }

  get routes(): ConsignmentRouteInfo[] {
    return this._routes;
  }

  set routes(routes: ConsignmentRouteInfo[]) {
    this.masterLevel.activeBorderTransportMeans.itinerary = routes.map(r => r.country?.code);
    this.refreshCustomsOfficeProvider();
    this.updateRoutes();
    this.updateFilingType();
  }

  get customsOfficeFirstEntryRequired(): boolean {
    return !this.isTemporaryStorageFromEuCountryToNL();
  }

  private isTemporaryStorageFromEuCountryToNL = () => {
    return ConsignmentUtils.isDutchPort(this.masterLevel.placeOfUnloading)
      && this.utils.isTemporaryStorageOnlyConsignment(this.consignmentProcess.filing.filingType)
      && this.itinerary.every(c => ConsignmentUtils.isEuCountryOrNorthernIreland(c, this.masterLevel.placeOfLoading?.locationUnCode));
  }

  get previousEnsDocumentRequired(): boolean {
    return ConsignmentUtils.isDutchPort(this.masterLevel.placeOfUnloading)
      && this.utils.isTemporaryStorageOnlyConsignment(this.consignmentProcess.filing.filingType)
      && this.itinerary.some(c => !ConsignmentUtils.isEuCountryOrNorthernIreland(c, this.masterLevel.placeOfLoading?.locationUnCode));
  }

  updatePreviousDocument = (documentIdentification: string) => {
    this.masterLevel.previousDocument.identification = documentIdentification;
    this.updateFilingType();
  }

  addRoute = () => {
    this._routes.push({
      country: {}
    });
  }

  remove = () => {
    openConfirmationModalWithCallback((confirmed) => {
      if (confirmed) {
        sendCommand("com.portbase.bezoekschip.common.api.consignments.commands.InvalidateConsignment", <IE3InvalidateConsignment>{
          consignmentProcessId: this.consignmentProcess.consignmentProcessId,
          consignmentNumber: this.masterLevel.consignmentNumber,
          cargoDeclarantId: this.consignmentProcess.filing.declarant.iamConnectedId
        });
      }
    }, ModalConfirmAutofocus, <ModalConfirmAutofocusData>{
      type: "danger",
      title: "Remove consignment",
      message: "You are about to remove consignment: " + this.masterLevel.consignmentNumber,
      question: "Are you sure that you want to remove this consignment?",
      confirmText: "Yes",
      cancelText: "No"
    }, 'static');
  }

  cancel = () => {
    clearValidation(this.elementRef);
    this.editMode = !this.editMode && !this.consignmentProcess.cancelled;
  }

  toggleEdit = () => {
    if (checkValidity(this.elementRef)) {
      this.editMode = !this.editMode && !this.consignmentProcess.cancelled;
    }
  }

  startEditing = () => this.editMode = true

  save = () => {
    if (this.isNewConsignment) {
      this.websocket.initialise(`/api/ui/${btoa(this.consignmentProcess.consignmentProcessId)}`,
        (update: IE3ConsignmentProcess) => this.updateFromConsignment(update), true);
    }
    this.processModel(false);
    const consignment = cloneDeep(this.masterLevel);
    sendCommand("com.portbase.bezoekschip.common.api.consignments.commands.SaveConsignment", <IE3SaveConsignment>{
      consignment: consignment,
      filing: this.consignmentProcess.filing
    }, () => {
      AppContext.registerSuccess("Consignment saved successfully");
      formDataSaved();
      this.editMode = false;
    })
  }

  declare = () => {
    if (this.isNewConsignment) {
      this.websocket.initialise(`/api/ui/${btoa(this.consignmentProcess.consignmentProcessId)}`,
        (update: IE3ConsignmentProcess) => this.updateFromConsignment(update), true);
    }
    if (this.processModel(true)) {
      if (checkValidity(this.elementRef)) {
        ConsignmentUtils.declareConsignment(this.consignmentProcess);
      } else if (ConsignmentUtils.allowedToEdit()) {
        this.editMode = !this.consignmentProcess.cancelled;
        publishEvent("validationFailed");
      }
    }
  }

  processModel = (registerErrors: boolean) => ConsignmentUtils.validateMasterConsignment(
    this.consignmentProcess, registerErrors);

  createNewConsignment = (transportEquipment?: { [containerIdentificationNumber: string]: IE3TransportEquipment },
                          crn?: string): IE3ConsignmentProcess => {
    return ({
      consignmentMasterLevel: {
        activeBorderTransportMeans: {
          vehicle: {},
          itinerary: []
        },
        previousDocument: {},
        transportEquipmentMap: transportEquipment || {},
        goodsItems: [],
        consignmentsHouseLevel: [],
      },
      filing: {
        declarant: AppContext.userProfile.organisation,
        filingType: null,
        crn: crn
      }
    })
  }

  get isEditable() {
    return ConsignmentUtils.isEditable(this.consignmentProcess);
  }

  get isAllowedToDeclare() {
    return ConsignmentUtils.isAllowedToDeclare(this.consignmentProcess);
  }

  get isDutchPort() {
    return ConsignmentUtils.isDutchPort(this.consignmentProcess?.consignmentMasterLevel?.placeOfUnloading);
  }

  get ensEnabledOfDeclarant(): boolean {
    const declarant = this.consignmentProcess?.filing?.declarant ? this.consignmentProcess.filing.declarant : AppContext.userProfile.organisation;
    return declarant.ensEnabled == undefined || declarant.ensEnabled;
  }

  get ensRequiredBasedOnItinerary() {
    if (this.portItinerary?.length) {
      const previousPort = this.portItinerary[this.portItinerary.length - 2];
      if (previousPort) {
        return !ConsignmentUtils.isEuCountryOrNorthernIreland(
          previousPort.port.countryUnCode, previousPort.port.locationUnCode);
      }
    }
    const placeOfUnloading = this.consignmentProcess?.consignmentMasterLevel?.placeOfUnloading;
    if (placeOfUnloading) {
      const itinerary = this.itinerary;
      const indexOfPlaceOfUnloading = itinerary.indexOf(itinerary.find(i => i === placeOfUnloading.countryUnCode));
      if (indexOfPlaceOfUnloading < 0) {
        return true;
      }
      const countryBeforeUnloading = itinerary[indexOfPlaceOfUnloading === 0 ? indexOfPlaceOfUnloading : indexOfPlaceOfUnloading - 1];
      return !ConsignmentUtils.isEuCountryOrNorthernIreland(countryBeforeUnloading, this.consignmentProcess?.consignmentMasterLevel?.placeOfLoading?.locationUnCode);
    }
    return true;
  }

  get countriesForCustomsOfficeOfFirstEntry(): string[] {
    if (this.portItinerary?.length) {
      return lodash.takeRight(this.portItinerary, 2).map(p => p.port.countryUnCode);
    }
    const itinerary = this.itinerary;
    const placeOfUnloading = this.consignmentProcess?.consignmentMasterLevel?.placeOfUnloading;
    const indexPlaceOfUnloading = placeOfUnloading ? this.itinerary.indexOf(itinerary.find(i => i === ConsignmentUtils.getCountryCode(placeOfUnloading))) : -1;
    return this.itinerary.slice(Math.max(indexPlaceOfUnloading-1, 0) + 1, indexPlaceOfUnloading + 1);
  }

  setBulkAuthorisations = (authorisations: BezoekschipOrganisation[]) =>
    this.masterLevel.bulkAuthorisations = authorisations || [];

  addSupportingDocument = () => this.masterLevel.supportingDocuments.push({});

  deleteSupportingDocument = (item: IE3SupportingDocument) => removeItem(this.masterLevel.supportingDocuments, item);

  setConsignmentProcessId = (consignmentNumber: string) => {
    this.consignmentProcess.consignmentProcessId = `${AppContext.userProfile.organisation.iamConnectedId}_${consignmentNumber}`;
  }

  billOfLadingTypeChanged = (value: BillOfLadingType) => {
    if (value === BillOfLadingType.Straight && this.masterLevel.consignmentsHouseLevel?.length > 1) {
      AppContext.registerError("Unable to change from master to straight because there are more then 1 house B/Ls in this consignment. For straight there is only 1 house B/L possible");
      setTimeout(() => {
        this.billOfLadingFilingType.billOfLadingType = BillOfLadingType.Master;
        this.updateFilingType();
      }, 0);
      return;
    }
    this.billOfLadingFilingType.billOfLadingType = value;
    const hasMasterData = (): boolean => !!this.masterLevel.consignee || !!this.masterLevel.consignor
      || !!this.masterLevel.goodsItems?.length;
    if (value === BillOfLadingType.Straight && hasMasterData() && !this.masterLevel.consignmentsHouseLevel?.length) {
      this.migrateMasterDataToHouse();
      this.updateFromConsignment(this.consignmentProcess);
    }
    this.updateFilingType();
  }

  migrateMasterDataToHouse = () => {
    const houseLevel = HouseConsignmentDetailsComponent.createHouseConsignment(this.masterLevel);
    houseLevel.goodsItems = this.masterLevel.goodsItems;
    this.masterLevel.consignmentsHouseLevel.push(houseLevel);
    this.masterLevel.goodsItems = [];
    this.masterLevel.consignor = null;
    this.masterLevel.consignee = null;
  }

  updateFilingType = (): void => {
    this.ngZone.runOutsideAngular(() => {
      if (this.ensEnabledOfDeclarant && this.ensRequiredBasedOnItinerary) {
        this.handleEnsOrDefaultCondition();
      } else if (this.isPassingThrough || this.isDutchPort) {
        this.handleDutchPortCondition();
      }
      this.consignmentProcess.filing.straight = ConsignmentUtils.isTemporaryStorageOnlyConsignment(this.consignmentProcess.filing.filingType)
      && this.billOfLadingFilingType.billOfLadingType === BillOfLadingType.Straight;
      if (this.previousEnsDocumentRequired) {
        this.masterLevel.previousDocument.type = this.masterLevel.previousDocument.type || IE3CL214.N355;
      }
    });
  }

  private handleEnsOrDefaultCondition(): void {
    if (this.billOfLadingFilingType.modeOfTransportType === ModeOfTransportType.Road) {
      this.consignmentProcess.filing.filingType = IE3FilingType.F50;
      this.billOfLadingFilingType = cloneObject(this.billOfLadingFilingTypeMapper[this.consignmentProcess.filing.filingType]) || {};
    } else {
      this.updateFilingTypeAndBillOfLadingTypes();
    }
  }

  private handleDutchPortCondition(): void {
    // Change to TSD when no ENS have been done
    if (!this.ensRequiredBasedOnItinerary) {
      if (this.billOfLadingFilingType.filing === Filing.Multiple) {
        AppContext.registerError("Automatically changed filing to Single filing because Multiple filing isn't allowed without ENS", "info");
        setTimeout(() => {
          this.billOfLadingFilingType.filing = Filing.Single;
          this.updateFilingType();
        }, 0);
        return;
      }
    }
    this.consignmentProcess.filing.filingType = this.billOfLadingFilingType.filing === Filing.Multiple
      ? IE3FilingType.TSR : IE3FilingType.TSD;
    const billOfLadingFilingType = cloneDeep(
      this.billOfLadingFilingTypeMapper[this.consignmentProcess.filing.filingType]
    );
    billOfLadingFilingType.billOfLadingType = this.consignmentProcess.filing.filingType === IE3FilingType.TSD
      ? this.utils.isRoadConsignment(this.consignmentProcess) ? BillOfLadingType.Master
        : this.billOfLadingFilingType.billOfLadingType || (this.consignmentProcess.filing.straight
        ? BillOfLadingType.Straight : BillOfLadingType.Master)
      : BillOfLadingType.Master;
    this.billOfLadingFilingType = billOfLadingFilingType;
    this.billOfLadingTypes = this.consignmentProcess.filing.filingType === IE3FilingType.TSD
      ? [BillOfLadingType.Master, BillOfLadingType.Straight]
      : [BillOfLadingType.Master];
  }

  private updateFilingTypeAndBillOfLadingTypes(): void {
    const foundEntry = Object.entries(this.billOfLadingFilingTypeMapper)
      .find(e => lodash.isEqual(e[1], this.billOfLadingFilingType));
    this.consignmentProcess.filing.filingType = foundEntry ? foundEntry[0] as IE3FilingType : null;
  }

  addNotifyParty = (): void => {
    const parties = this.masterLevel.notifyParties || [];
    parties.push((<IE3Party>{["isNewRecord"]: true, name: "New notify party", address: {}, communications: []}) as any);
    this.masterLevel.notifyParties = parties;
  }

  checkPartyDeletion = (party: IE3Party, index: number) => {
    if (!party) {
      this.masterLevel.notifyParties.splice(index, 1);
    }
  }

  addSupplyChainActor = (): void => {
    const actors = this.masterLevel.additionalSupplyChainActors || [];
    actors.push({["isNewRecord"]: true} as any);
    this.masterLevel.additionalSupplyChainActors = actors;
  }

  checkSupplyChainActorDeletion = (actor: IE3AdditionalSupplyChainActor, index: number) => {
    if (!actor) {
      this.masterLevel.additionalSupplyChainActors.splice(index, 1);
    }
  }

  showDetails = (): boolean => !this.isNewConsignment || !!this.consignmentProcess?.filing?.filingType;

  onCrnChange = (value: VisitSummary) => {
    if (!value) {
      this.updateVisitFields(null);
    }
    if (value) {
      this.updateDataFromVisit(value.crn, true);
    } else {
      this.updateVisitFields(null);
    }
  }

  private setTerminalsOfVisit(v: Visit): Observable<Terminal[]> {
    return this.terminalsOfVisit = of(v.visitDeclaration.portVisit.berthVisits.filter(v => !!v.berth).map(v => v.berth))
      .pipe(mergeMap(terminals => sendQuery("com.portbase.bezoekschip.common.api.visit.GetTerminals", {
        portUnCode: v.portOfCall.port.locationUnCode,
        terminalCodes: terminals.map(t => t.terminalCode)
      }, {caching: true})));
  }

  private setPortItinerary = (v: Visit): Observable<PortItinerary[]> => {
    this.portItinerary = v.visitDeclaration.previousPorts.map(p => (<PortItinerary>{
      port: p.port,
      departure: p.departure,
      arrival: p.arrival
    })).reverse().concat([{
      port: v.portOfCall.port,
      arrival: v.visitDeclaration.portVisit.etaPort,
      departure: v.visitDeclaration.portVisit.etdPort
    }]);
    return this.itineraryOfVisit = of(this.portItinerary);
  }

  private updateDataFromVisit = (crn: string, forceUpdate: boolean) =>
    sendQuery("com.portbase.bezoekschip.common.api.visit.GetVisit", <GetVisit>{crn: crn})
      .subscribe((v: Visit) => {
        this.setTerminalsOfVisit(v);
        this.setPortItinerary(v);
        this.updateVisitFields(v, forceUpdate);
      });

  private updateVisitFields(v?: Visit, forceUpdate: boolean = false) {
    const consignmentMasterLevel = this.masterLevel;
    const activeBorderTransportMeans = consignmentMasterLevel.activeBorderTransportMeans;
    this.consignmentProcess.filing.crn = v?.crn;
    if (v != null) {
      this.isPassingThrough = VisitContext.isPassingThrough(v);
      activeBorderTransportMeans.vehicle = <IE3Vehicle>{
        identificationNumber: v.vessel.imoCode,
        name: v.vessel.name,
        nationality: v.vessel.flagCountryUnCode,
        typeOfIdentification: IE3CL750.N10,
        typeOfMeansOfTransport: v.vessel.motUnCode
      };
      this.setTerminalsOfVisit(v).subscribe(t => {
        if (this.masterLevel.placeOfUnloading?.locationUnCode === v.portOfCall.port.locationUnCode) {
          this.masterLevel.dischargeTerminal = this.masterLevel.dischargeTerminal || (t?.length === 1 ? t[0] : null);
        } else {
          this.masterLevel.dischargeTerminal = this.masterLevel.terminalLicense = null;
        }
      });
    } else {
      this.isPassingThrough = false;
      this.itineraryOfVisit = null;
      this.portItinerary = null;
      this.terminalsOfVisit = null;
    }
    consignmentMasterLevel.carrier = forceUpdate ? v?.visitDeclaration.arrivalVoyage.carrier
      : consignmentMasterLevel.carrier || v?.visitDeclaration.arrivalVoyage.carrier;
    consignmentMasterLevel.agentVoyageNumber = forceUpdate ? v?.visitDeclaration.arrivalVoyage.voyageNumber
      : consignmentMasterLevel.agentVoyageNumber || v?.visitDeclaration.arrivalVoyage.voyageNumber;
    activeBorderTransportMeans.estimatedDateAndTimeOfArrival = activeBorderTransportMeans.estimatedDateAndTimeOfArrival
      || v?.visitDeclaration.portVisit.etaPort;
    consignmentMasterLevel.placeOfUnloading = forceUpdate ? this.convertPortToPlace(v?.portOfCall.port)
      : consignmentMasterLevel.placeOfUnloading || this.convertPortToPlace(v?.portOfCall.port);
    activeBorderTransportMeans.itinerary = this.getUniqueConsecutive(
      (v?.visitDeclaration.previousPorts.map(p => p.port.countryUnCode).reverse() || []).concat(
        consignmentMasterLevel.placeOfUnloading ? [consignmentMasterLevel.placeOfUnloading.countryUnCode] : []));
    this.updateRoutes();
    this.refreshCustomsOfficeProvider();
    if (this.initialised) {
      this.updateFilingType();
    }
  }

  private getUniqueConsecutive = (arr: string[]): string[] => {
    return arr.filter((item, index, self) => index === 0 || item !== self[index - 1]);
  }

  updateRoutes() {
    this.getRoutesOfConsignment().subscribe(r => this._routes = r);
  }

  get transportEquipmentCount(): number {
    return Object.keys(this.masterLevel.transportEquipmentMap).length;
  }

  private convertPortToPlace(port: Port | undefined): IE3Place {
    return port ? {
      name: port.name,
      countryUnCode: port.countryUnCode,
      euLocation: port.euPort,
      locationUnCode: port.locationUnCode
    } : null;
  }

  typeOfIdentificationFormatter = (value: IE3CL750) => {
    switch (value) {
      case IE3CL750.N10:
        return "Vessel";
      case IE3CL750.N20:
        return "Wagon Number";
      case IE3CL750.N21:
        return "Train Number";
      case IE3CL750.N30:
        return "Registration Number of the Road Vehicle";
      case IE3CL750.N31:
        return "Registration Number of the Road Trailer";
      case IE3CL750.N41:
        return "Registration Number of the Aircraft";
      case IE3CL750.N80:
        return "European Vessel Identification Number (ENI Code)";
      default:
        return value;
    }
  }

  typeOfMeansOfTransportFormatter = (code: string) => code && typesOfMeansOfTransport[code]
    ? `${code} - ${typesOfMeansOfTransport[code]}` : "";

  countryFormatter = (value: Country | string) => typeof value === "string" ? value : value.code;

  allTypeOfMeansOfTransportKeys = Object.keys(typesOfMeansOfTransport);
}

export interface MasterConsignmentDetailsComponentData {
  consignmentProcessId: string;
  crn: string,
  transportEquipmentMap: { [containerIdentificationNumber: string]: IE3TransportEquipment };
  cachedConsignmentProcess: IE3ConsignmentProcess;
  copyOfConsignmentProcessId?: string;
}

interface BillOfLadingFilingType {
  modeOfTransportType?: ModeOfTransportType;
  billOfLadingType?: BillOfLadingType;
  filing?: Filing;
}

enum BillOfLadingType {
  Straight = "Straight",
  Master = "Master"
}

enum Filing {
  Single = "Single",
  Multiple = "Multiple"
}

enum ModeOfTransportType {
  Maritime = "Maritime",
  Road = "Road"
}

interface PortItinerary {
  port?: Port;
  arrival?: string;
  departure?: string;
}
